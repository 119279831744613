import { render, staticRenderFns } from "./notes.vue?vue&type=template&id=634c8a10&scoped=true&v-on%3Aclone=clone&"
import script from "./notes.vue?vue&type=script&lang=js&"
export * from "./notes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "634c8a10",
  null
  
)

export default component.exports