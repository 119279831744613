var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dragResize',{ref:"dr",attrs:{"box":{
            x: _vm.note.x,
            y: _vm.note.y,
            w: _vm.note.width,
            h: _vm.note.height,
            z: _vm.note.z,
            locked: _vm.note.locked || _vm.note.editing,
            grid: _vm.note.grid,
            anchorGrid: _vm.anchor.grid,
        },"scale":_vm.anchor.scale}},[_c('Note',{attrs:{"note":_vm.note,"anchor":_vm.anchor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }