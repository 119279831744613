<template>
    <div class="anchor">
        <v-icon class="material-icons">home</v-icon>
    </div>
</template>

<script>
    export default {
        name: "anchor",
    }
</script>

<style scoped>
    .anchor {
        position: absolute;
        top: 0;
        left: 0;
    }
</style>